import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import PortableText from 'react-portable-text';

import {
	Button,
	Typography,
	Grid,
	makeStyles,
	useMediaQuery,
} from '@material-ui/core';
import { Link } from 'gatsby';

import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '6rem 0 8rem 0',
		width: '100%',
		[theme.breakpoints.down('lg')]: {
			padding: '4rem',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '4rem 0',
		},
	},
	bgImg: {
		padding: '0px',
		overflow: 'visible',
		borderRadius: '20px',
		'&, &:before, &:after': {
			borderRadius: '20px',
			boxShadow: '0 10px 40px 0px rgba(0,0,0,0.2)',
		},
		[theme.breakpoints.down('lg')]: {
			padding: '1rem 0',
		},
		[theme.breakpoints.down('md')]: {
			padding: '.5rem 0',
			//overflow: 'hidden',
		},
	},
	button: {
		background: 'white',
		color: '#002D5C',
		padding: '16px 32px',
		opacity: 1,
		fontSize: '1.1rem',
		fontWeight: 500,
		zIndex: 1000,
		transition: 'all 0.35s ease !important',
		'&:hover': {
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('md')]: {
			margin: '2rem 0 .5rem',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0',
			padding: '14px 36px',
		},
		[theme.breakpoints.down('xs')]: {
			margin: '2rem 0 0 0',
		},
	},
	noBgButton: {
		background: '#2a7abc',
		color: 'white',
		padding: '16px 32px',
		opacity: 1,
		fontSize: '1.1rem',
		fontWeight: 500,
		zIndex: 1000,
		transition: 'all 0.35s ease !important',
		'&:hover': {
			background: '#2a7abc',
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('md')]: {
			margin: '2rem 0 .5rem',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			margin: '1rem 0 .5rem',
		},
	},
  bannerRow: {
    height: '100%', 
    padding: '2rem 5rem 2rem 0',
 		[theme.breakpoints.down('md')]: {
			//textAlign: 'flex-start',
			padding: '2rem 1rem 2rem 2rem',
		},
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
	content: {
		color: '#FFF',
		lineHeight: '31px',
		fontSize: '1.75rem',
    fontWeight: 500,
    maxWidth: '600px',
		// [theme.breakpoints.down('md')]: {
		// 	textAlign: 'flex-start',
		// 	padding: '0 25px',
		// },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
	},
	calloutHeader: {
		fontSize: '2.75rem',
		lineHeight: 1.2,
		color: '#FFF',
		fontWeight: 700,
		//padding: '2rem 0 1rem',
		[theme.breakpoints.down('md')]: {
			textAlign: 'flex-start',
      lineHeight: 1.1,
			// padding: '25px 0 0 25px',
		},
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.15rem',
    },
		// [theme.breakpoints.down('xs')]: {
		// 	fontSize: '1.75rem',
		// 	padding: '2rem 0 0 0',
		// },
	},
	noBgCalloutHeader: {
		fontSize: '2.25rem',
		lineHeight: 1.2,
		color: theme.workwaveBlue,
		marginBottom: '1rem',
		[theme.breakpoints.down('md')]: {
			textAlign: 'flex-start',
			padding: '15px 0 0 15px',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem',
		},
	},
	calloutSubHeader: {
		color: '#FFF',
		lineHeight: '38px',
		fontSize: '24px',
		fontWeight: 600,
		// [theme.breakpoints.down('md')]: {
		// 	textAlign: 'left',
		// 	padding: '0 15px',
		// },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
	},
	stylized: {
		textDecoration: 'underline',
	},
	imgRight: {
		position: 'absolute',
    right: '-140px',
    width: '400px',
		[theme.breakpoints.down('md')]: {
			position: 'relative',
      right: '0px',
      width: '320px',
		},
		[theme.breakpoints.down('sm')]: {
      right: '12px',
    },
		[theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: '-3px',
      top: '-82px',
      width: '239px',
    },
	},
}));

//NOTE: THIS COMPONENT IS NO LONGER REUSABLE FOR THE TIME BEING

export const HomeNewsBar = ({
	background,
	header,
	subheader,
	newsBarText,
	newsBarLink,
	newsBarImage,
  newsBarMobileImage,
	internalLink,
	whyrealgreen,
  home,  
}) => {
	const classes = useStyles();

	const { handleModalClick } = useContext(FormModalContext);

	const lg = useMediaQuery('(max-width: 1260px)');
  const md = useMediaQuery('(max-width: 1024px)');
  const sm = useMediaQuery('(max-width: 715px)');

	return (
		<div className={classes.root}>
			<BgImage
				style={{
					borderRadius: '20px',
					boxShadow: '0 10px 40px 0px rgba(0,0,0,0.2)',
					// backgroundSize: 'cover',
					backgroundPosition: 'bottom',
          marginTop: (!md && whyrealgreen) || (sm && home) || (sm && whyrealgreen) ? '4rem' : '0',
				}}
				className={classes.bgImg}
				image={background?.asset?.gatsbyImageData}>
				<Grid
					container
					direction='row'
					justifyContent={lg ? 'center' : 'space-around'}
					alignItems='center'
					style={{ height: '100%', borderRadius: '20px' }}>
					<Grid
						item
						container
						direction='row'
						xs={12}
            sm={7}
						lg={!background ? 7 : 10}
						justifyContent={lg ? 'flex-start' : 'space-evenly'}
						alignItems={'center'}
            className={classes.bannerRow}>
						{typeof header !== 'string' ? (
							<PortableText
								content={header}
								serializers={{
									h3: ({ children }) => (
										<Typography
											variant='h3'
											className={
												!background
													? classes.noBgCalloutHeader
													: classes.calloutHeader
											}>
											{children}
										</Typography>
									),
									h4: ({ children }) => (
										<Typography variant='h4' className={classes.calloutSubHeader}>
											{children}
										</Typography>
									),
                  normal: ({ children }) => (
                    <Typography variant='body1' className={classes.content}>
                      {children}
                    </Typography>
                  ),
									strong: ({ children }) => (
										<span style={{ fontWeight: 700 }}>{children}</span>
									),
									em: ({ children }) => (
										<span className={classes.stylized}>{children}</span>
									),
								}}
							/>
						) : (
							<Typography
								variant='h2'
								className={
									!background
										? classes.noBgCalloutHeader
										: classes.calloutHeader
								}>
								{header}
							</Typography>
						)}
						{newsBarLink ? (
							internalLink ? (
								<Link to={`/${newsBarLink}`} style={{ textDecoration: 'none' }}>
									<Button
										variant='contained'
										size='large'
										className={
											!background ? classes.noBgButton : classes.button
										}>
										{newsBarText}
									</Button>
								</Link>
							) : (
								<a
									href={newsBarLink}
									target='_blank'
									style={{ textDecoration: 'none' }}>
									<Button
										variant='contained'
										size='large'
										className={
											!background ? classes.noBgButton : classes.button
										}>
										{newsBarText}
									</Button>
								</a>
							)
						) : (
							<Button
								variant='contained'
								size='large'
								className={!background ? classes.noBgButton : classes.button}
								onClick={handleModalClick}>
								{newsBarText}
							</Button>
						)}

						{/* <Typography
							variant='body1'
							className={classes.calloutSubHeader}
							style={{ color: !background ? '#4B5B69' : null }}>
							{subheader}
						</Typography> */}
					</Grid>
					<Grid
						item
						container
						alignItems='center'
						justifyContent='center'
						xs={12}
            sm={5}
						lg={2}
						style={{ height: '100%' }}>
						<GatsbyImage
							image={sm && newsBarMobileImage ? newsBarMobileImage?.asset?.gatsbyImageData : newsBarImage?.asset?.gatsbyImageData}
							alt='img-right'
							className={classes.imgRight}
						/>
					</Grid>
				</Grid>
			</BgImage>
		</div>
	);
};
